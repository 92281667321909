import React,{useEffect, useState} from 'react'
import CreateVendors from './CreateVendors';

function Vendors() {

  return (
    <div>
      <CreateVendors/>
    </div>

  )
}

export default Vendors

